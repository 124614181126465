document.addEventListener("DOMContentLoaded", function () {
  function submitForm() {
    var fullName = document.getElementById("fullName").value;
    var phone = document.getElementById("phone").value;
    var email = document.getElementById("email").value;
    var message = document.getElementById("message").value;

    var whatsappMessage = `Hi, I would like to book an appointment.%0A%0AFull Name: ${fullName}%0APhone: ${phone}%0AMessage: ${message}`;

    window.open(`https://wa.me/1234567890?text=${whatsappMessage}`, "_blank");
  }

  document.querySelector("form").addEventListener("submit", function (event) {
    event.preventDefault();
    submitForm();
  });
});
